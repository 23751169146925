






















import { Component, Vue, Watch } from "vue-property-decorator";

@Component
export default class Unit extends Vue {
  private mode = "overview";
  private name = "Settings";
  private modulename = "setting";
  private selectedItem: any = null;
  private tempItem: any = null;
  private data: any = { prefix: "", suffix: "", precision: 0, counter: 0 };

  @Watch('mode')
  modeChanged(value: string) {
    this.clear();
  }

  save () {
    this.$store.dispatch(`${this.modulename}/saveVoucherNumbering`,{ ...this.data, callback: this.onSaved });
  }

  remove () {
    if (this.selectedItem) {
      this.$store.dispatch(`${this.modulename}/remove`,{ id: this.selectedItem, callback: this.onRemoved });
    }
  }

  onSaved (e: any, d: any) {
    let msg = ""
    if (d) {
      this.clear();
      msg  = `${this.name} Successfully Updated!`;
      this.$store.commit("SHOW_SUCCESS", msg)
    } else {
      msg = e.message;
      this.$store.commit("SHOW_ERROR", msg)
    }
  }

  onRemoved (e: any, d: any) {
    let msg = ""
    if (d) {
      this.clear();
      msg  = `${this.name} Successfully Removed!`;
      this.$store.commit("SHOW_SUCCESS", msg)
    } else {
      msg = e.message;
      this.$store.commit("SHOW_ERROR", msg)
    }
  }

  clear () {
    this.data = { prefix: "", suffix: "", precision: 0, counter: 0 };
    this.selectedItem = null;
    this.tempItem = null;
  }

  selectObject (sitem: any) {
    this.selectedItem = sitem;
    if (this.selectedItem) {
      const item = this.items.filter((i: any) => i._id && i._id.toString() === this.selectedItem.toString())[0];
      if (item) {
        this.data = JSON.parse(JSON.stringify(item));
      }
    }
  }

  get items () {
    const items = this.$store.state[this.modulename].items;
    return items;
  }

  updateSettings() {
    const items = this.items;
    const prefix_settings = items.filter((i: any) => i.name === "voucher-numbering-prefix")[0]
    const suffix_settings = items.filter((i: any) => i.name === "voucher-numbering-suffix")[0]
    const precision_settings = items.filter((i: any) => i.name === "voucher-numbering-precision")[0]
    const counter_settings = items.filter((i: any) => i.name === "voucher-numbering-counter")[0]

    if (prefix_settings) {
      this.data.prefix = prefix_settings.value;
    }

    if (suffix_settings) {
      this.data.suffix = suffix_settings.value;
    }

    if (precision_settings) {
      this.data.precision = precision_settings.value;
    }

    if (counter_settings) {
      this.data.counter = counter_settings.value;
    }
  }

  onSettingsLoaded(e: any, d: any) {
    if (d) {
      this.updateSettings();
    }
  }

  get canSave () {
    return this.data.precision >= 0 && this.data.counter !== ""
  }

  get canRemove () {
    return this.selectedItem
  }

  mounted () {
    this.$store.commit('FOOTER_VISIBLE', false);
    this.$store.dispatch(`${this.modulename}/load`, this.onSettingsLoaded);
  }

}
